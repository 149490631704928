/* You can add global styles to this file, and also import other style files */

$primary-color: #394672;
$positive-color: #00bfa5;
$negative-color: #ff5252;

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.fullwidth {
  width: 100%;
}

.fullheight {
  height: 100%;
}


.small-icon{
  vertical-align: middle;
  opacity: .5;
  font-size: 20px;
}


.align-right {
  text-align: right !important;
}

.align-left {
  text-align: left !important;
}

.align-center {
  text-align: center !important;
}

.valign-middle {
  vertical-align: middle;
}

.valign-top {
  vertical-align: top;
}

.valign-bottom {
  vertical-align: bottom;
}

.inline-block {
  display: inline-block;
}

.bold {
  font-weight: bold;
}

.underline {
  text-decoration: underline;
}

.clickable {
  cursor: pointer;
}

hr{
  border-color: $primary-color;
}

button,
input[type="button"],
.button {
  &.styled {
    padding: .4em 2em;
    display: inline-block;
    outline: 0px;
    border: 0px;
    box-sizing: border-box;
    border-radius: 0.5em;
    cursor: pointer;
    font-size: 1.3em;
    line-height: 1.5em;
    text-align: center;
    //   height: 3em;
    max-height: 100%;
    max-width: 100%;
    background-color: $primary-color;
    color: white;
    white-space: nowrap;

    &.outline{
      border:2px solid $primary-color;
      background-color: transparent;
      color: $primary-color;
    }

    &.limit-width {
      max-width: 500px;
    }

    &.large {
      padding: 0em 2em;
      font-size: 1.5em;
      line-height: 1.7em;
    }

    &:disabled,
    &.disabled {
      opacity: 0.5;
      cursor: default;
    }

  }
}

label{
  font-weight: 400;
}

table.styled {
  >tr {
    &:nth-child(odd) {
      background: rgba(0, 0, 0, 0.05);
    }

    >td,
    >th {
      padding: 10px;
      vertical-align: top;
    }

    &.head-row {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  .inactive {
    opacity: 0.6;
  }
}

.mat-tab-body-content {
  padding: 20px;
}


.positive {
  color: $positive-color;
}

.negative {
  color: $negative-color;
}
